import './App.css';
import loveBackground from './Assets/Hjerte baggrund png.png';

import TwoYearsAgo1 from './Assets/Twoyearsago1.JPEG';
import TwoYearsAgo2 from './Assets/Twoyearsago2.JPEG';
import OneyearAgo1 from './Assets/Oneyearago1.png';
import OneyearAgo2 from './Assets/IMG_6831.png'
import PrincessKittyPhoto from './Assets/Princess kitty Player background Image.png';


import {Parallax, ParallaxLayer} from '@react-spring/parallax';

const Locked = () => {

  function checkKodeord(e){
    e.preventDefault();
      var passInput = document.getElementById("kodeordTil3aar");
      if(passInput.value=="27022022"){
        alert('Rigtigt')
          window.location.replace('./3aar_game/3aar3/');
      }else{
          alert('Forkert...');
      }
  }

  return(
      <div>
          <form style={{display: 'block'}} id="lockedDoc">
              <input style={{backgroundColor: 'none'}} type="password" id="kodeordTil3aar" placeholder="kode" name="kodeord"/>
              <input type="submit" onClick={(e)=>checkKodeord(e)}/>
          </form>
      </div>
  )
}

function App() {

  return (
    <div className="App">


        <Parallax pages={6}>
          <ParallaxLayer
            offset={1}
            speed={1}
            factor={6}
            id='loveBackground'
            style={{backgroundRepeat: 'repeat-y', backgroundSize: 'contain'}}
            />

          <ParallaxLayer
            offset={4}
            speed={1}
            factor={3}
            id='gameBackground'
            style={{backgroundRepeat: 'repeat-y', backgroundSize: 'contain', border: 'solid 2px', backgroundColor: '#011e3b'}}
            />

          <ParallaxLayer 
            offset={4.2}
            speed={1.5}
            factor={1}
          >
            <img style={{position: 'absolute', right: 100}} src={PrincessKittyPhoto}></img>

          </ParallaxLayer>

          <ParallaxLayer
            offset={0}
            speed={1.5}
            factor={1}
            className='contentHeader'>
              <h1>Til nikita</h1>
          </ParallaxLayer>

          <ParallaxLayer
              className='contentBody'
              speed={.5}
              offset={1}
              factor={1}
              >
                <p className='TillykkeText'>
                  Tillykke med de 21 år!
                </p>
          </ParallaxLayer>



          <ParallaxLayer
            offset={2}
            factor={1}
            speed={.3}
            style={{textAlign: 'center', justifyContent: 'center', fontSize: 124}}>
                2 år siden i dag
                <div style={{display:'inline-flex', padding: 30, alignItems: 'center', justifyContent: 'center'}}>
                  <img style={{ width: '20%', position: 'static', padding: 30}} src={TwoYearsAgo1} alt='To år siden1' />
                  <img style={{ width: '20%', position: 'static', padding: 30}} src={TwoYearsAgo2} alt='To år siden2' />
                </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            factor={1}
            speed={.3}
            style={{textAlign: 'center', justifyContent: 'center', fontSize: 124}}>
                1 år siden i dag
                <p style={{fontSize: 24}}>plus minus</p>
                <div style={{display:'inline-flex', padding: 30, alignItems: 'center', justifyContent: 'center'}}>
                  <img style={{ width: '20%', position: 'static', padding: 30}} src={OneyearAgo1} alt='Et år siden1' />
                  <img style={{ width: '20%', position: 'static', padding: 30}} src={OneyearAgo2} alt='To år siden2' />
                </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            factor={2}
            speed={1}
            style={{alignContent:'center'}}
            >
              <div style={{textAlign: 'left', justifyContent: 'center', width: '60%'}}>
                <h2 style={{padding: 0, fontSize: '64px', color: 'white'}}>Eventyret om princesskitty</h2>
                <p className='jagtenP' style={{fontSize: '48px', paddingLeft: 10, color: 'white'}}>
                  Vi fejrer nu din fødselsdag for 3. år i streg,
                  og jeg mener derfor, at det er på tide at introducere dig til
                  princesskittys historie.
                  </p>

                <h2 style={{padding: 0, fontSize: '64px', color: 'white'}}>Før eventyret begynder:</h2>
                <ul style={{fontSize: '32px', paddingLeft: 10, color: 'white'}}>  
                  Princesskitty har overordnet en meget sød tand.
                  <br></br>
                  <br></br>
                  <li>Hun kan rigtig godt lide is. Is er derfor hendes navigation gennem eventyret.</li>
                  <br></br>
                  <li>Pandekager har et helt specielt sted i hendes hjerte. Hun stræber efter at spise alle de pandekager hun kan på sin vej.</li>
                  <br></br>
                  Hun bryder sig ikke om roser, hvepse... mange ting faktisk.
                  <br></br>
                  <br></br>
                  Held og lykke på din vej gennem princesskittys eventyr.
                </ul>
              </div>



                <a href='./game/V4' id='princessKittyGameBox' style={{display: 'flex', width: '768px', height: '256px', marginTop: 100, backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: 20, textDecoration: 'none', fontSize: 72, alignItems: 'center', justifyContent: 'center'}}>Start eventyret</a>
                <ParallaxLayer
                  offset={3}
                  factor={1}
                  speed={.3}
                  style={{color: 'white', textAlign: 'center', justifyContent: 'center', fontSize: 124}}>
                      ?
                      <div style={{display:'inline-flex', padding: 30, alignItems: 'center', justifyContent: 'center'}}>
                      <Locked/>
                      </div>
                </ParallaxLayer>
                
                
          
          
          
          </ParallaxLayer>
        </Parallax>      
    </div>

    



  );
}

export default App;
